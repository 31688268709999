import React, { useCallback, useMemo, useState } from 'react';
import {RegContainer, FooterButtons, CodeCountdown} from '../../../components';
import styled from 'styled-components';
import {
  Link,
  RegTitle,
  Input,
  IconView,
  IconCheck,
  Row,
  Col,
  Checkbox,
  Title2,
  IconCross
} from '../../../ui';
import { GU } from '@aragon/ui';
import { userActions } from '../../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { newObject } from '../../../utils/helpers';
import { resendEmailCode } from '../../../api/auth';
import { investorApi } from '../../../api/investor';
import { EMAIL_REGEX } from '../../../utils/static/app';
import { useTranslation } from 'react-i18next';

export const LoginViaEmail = () => {
  const [codeSent, setCodeSent] = useState(false);

  return (
    <RegContainer type='centered'>
      {codeSent ? <VerifyEmail /> : <InsertData goNext={() => setCodeSent(true)} />}
    </RegContainer>
  );
};

const InsertData = ({ goNext }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [saveAccount, setSaveAccount] = useState(false);
  const [data, setData] = useState({ email: '', password: '' });
  const [emailError, setEmailError] = useState('');
  const [isEmailFound, setIsEmailFound] = useState(null);
  const [emailValidated, setEmailValidated] = useState(false);

  const onChangeData = (key, value, data) => {
    setData(newObject(data, key, value));
  };

  const onChangeEmail = useCallback(
    (() => {
      let timer;

      return (...args) => {
        onChangeData(...args);

        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          validateEmail(args[1]);
        }, 800);
      };
    })(),
    []
  );

  const checkValidation = () => {
    return new Promise((resolve, reject) => {
      if (data.email) {
        investorApi.phoneEmailValidation(null, data.email.toLowerCase(), null).then((res) => {
          if (res.status === 200) {
            const { data } = res;

            if (data.validEmail !== undefined) {
              setIsEmailFound(!data.validEmail);
              data.validEmail ? reject() : resolve();
            }
          }
        });
      } else {
        setIsEmailFound(null);
        reject();
      }
    });
  };

  const validateEmail = (email) => {
    const validEmail = EMAIL_REGEX.test(email.toLowerCase());

    !validEmail ? setEmailError(t('Invalid e-mail address')) : setEmailError('');

    if (!emailValidated) setEmailValidated(true);
  };

  const handleNext = async () => {
    try {
      await checkValidation();

      dispatch(
        userActions.auth(
          {
            ...data,
            email: data.email.toLowerCase()
          },
          goNext,
          t
        )
      );
    } catch (e) {
      console.error(e);
    }
  };

  const disableNext = useMemo(() => {
    return !emailValidated || !data.email || !data.password || emailError;
  }, [emailValidated, data.email, data.password, emailError]);

  return (
    <div>
      <RegTitle>{t('Login to start your session')}</RegTitle>

      <Input
        label={t('E-mail')}
        value={data.email}
        onChange={(e) => onChangeEmail('email', e.target.value, data)}
        error={emailError}
        icon={isEmailFound ? <IconCheck /> : <IconCross hide={isEmailFound === null} />}
        textTransform={'lowercase'}
        marginBottom={3 * GU}
      />

      <Input
        label={t('Password')}
        value={data.password}
        onChange={(e) => onChangeData('password', e.target.value, data)}
        type={showPassword ? 'text' : 'password'}
        icon={<IconView onClick={() => setShowPassword(!showPassword)} />}
      />

      <Row
        justifyContent={'space-between'}
        alignItems={'center'}
        marginTop={2 * GU}
        marginBottom={4 * GU}
      >
        {/*<Col auto>*/}
        {/*  <Checkbox*/}
        {/*    label={'Remember me'}*/}
        {/*    checked={saveAccount}*/}
        {/*    onChange={(checked) => setSaveAccount(checked)}*/}
        {/*  />*/}
        {/*</Col>*/}

        <Col auto>
          <span>{t('Forgot you password?')} </span>
          <Link to={'/reset-password'}>{t('Reset password')}</Link>
        </Col>
      </Row>

      <FooterButtons onNext={handleNext} nextDisabled={disableNext} />

      <RepeatSendTitle>
        <span>{t('Don’t have an account yet?')} </span>
        <Link to={'/registration/investor'}>{t('Sign up')}</Link>
      </RepeatSendTitle>
    </div>
  );
};

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { email, id } = useSelector((state) => state.user);
  const [code, setCode] = useState('');

  const userState = useSelector((state) => state.user);
  console.log('userState', userState);

  const handleNext = () => {
    dispatch(userActions.setTokenByEmail({ email: email, code: code , id: id}, t));
  };

  return (
    <div>
      <Title>{t('Verify your account')}</Title>

      <Subtitle>[{t('The verification code has been sent on your email')}]</Subtitle>

      <Input
        label={t('Verification code')}
        value={code}
        onChange={(e) => setCode(e.target.value)}
        type={'number'}
      />

      <RepeatSendTitle2>
          <CodeCountdown sendCode={() => resendEmailCode(email, id)}/>
      </RepeatSendTitle2>

      <FooterButtons onNext={handleNext} />
    </div>
  );
};

const RepeatSendTitle = styled.p`
  font-size: 18px;
  margin: ${GU * 5}px 0 ${GU * 8}px 0;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${3 * GU}px;
`;
const Subtitle = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #212b36;
  margin-bottom: 62px;
`;
const RepeatSendTitle2 = styled.p`
  margin: ${GU * 5}px 0 ${GU * 8}px 0;
`;
