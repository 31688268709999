import React, { useEffect, useState } from 'react';
import {RegContainer, StepsPanel, FooterButtons, CodeCountdown} from '../../../../components';
import styled from 'styled-components';
import { Input, LinkButton, Title2 } from '../../../../ui';
import { GU } from '@aragon/ui';
import { individualSteps } from '../../../../utils/static';
import { useDispatch, useSelector } from 'react-redux';
import {investorApi, sendEmailCode, sendPhoneCode, verifyPhone} from '../../../../api/investor';
import { investorActions } from '../../../../store/investor';
import { entitiesActions } from '../../../../store/entities';
import {userActions, userReducer} from '../../../../store/user';
import { kycApi } from '../../../../api/kyc';
import { useTranslation } from 'react-i18next';

export const Step3 = ({ stepNumber, goBack, goNext }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { phone, id } = useSelector((state) => state.investor);
  const [code, setCode] = useState('');

  const handleNext = async () => {
    try {
      dispatch(entitiesActions.showLoader(t('Processing...') + '...'));

      const res = await verifyPhone(code, id);

      if (res.status === 200) {
        await investorApi.createPersonalAccount();
        kycApi.createVerificationInstance();
        dispatch(investorActions.resetInvestor());
        dispatch(userActions.setRole('investor'));
        dispatch(userReducer.setShowUserGuide(true));
      }
    } catch (e) {
      console.log('verifyPhone', e);
      const errorMessage = e.response?.data?.message;
      if (errorMessage === 'CONFIRM_CODE_IS_WRONG') {
        dispatch(entitiesActions.addToast(t('Wrong code')));
      }
    } finally {
      dispatch(entitiesActions.hideLoader());
    }
  };

  return (
    <StepsPanelContainer>
      <StepsPanel title={t('Registration')} step={stepNumber} steps={individualSteps.map((item) => t(item))} />

      <RegContainer type='centered'>
        <Title onClick={goNext}>{t('Verify your phone number')}</Title>

        <Subtitle>
          {t('The verification code has been sent on your phone number:')}
          <br />
          <LinkButton>{phone}</LinkButton>
        </Subtitle>

        <Input
          label={t('Verification code')}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          type={'number'}
        />

        <RepeatSendTitle>
          <CodeCountdown sendCode={sendPhoneCode} isInitialCall={true}/>
        </RepeatSendTitle>

        <FooterButtons
          NextButtonTitle={t('Send')}
          goBack={goBack}
          onNext={handleNext}
          withoutArrow
          nextDisabled={!code}
          hideBack
        />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${3 * GU}px;
`;
const Subtitle = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #212b36;
  margin-bottom: 62px;
`;
const RepeatSendTitle = styled.p`
  margin: ${GU * 5}px 0 ${GU * 8}px 0;
`;
