import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  userRole: undefined,
  email: null,
  hideAppContainer: false,
  showUserGuide: false,
  language: { code: 'EN' },
  id: null,
};

const user = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setRole(state, action) {
      state.userRole = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },
    setHideAppContainer(state, action) {
      state.hideAppContainer = action.payload;
    },
    setShowUserGuide(state, action) {
      state.showUserGuide = action.payload;
    },
    logout(state, action) {
      state.userRole = null;
      state.email = null;
    },
    setLanguage(state, action) {
      state.language = action.payload;
    }
  }
});

export const { setRole, setEmail, setId, setHideAppContainer, setShowUserGuide, logout } = user.actions;

export default user.reducer;
